<template>
  <div class="text-center" v-if="loading">
    <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
    ></v-progress-circular>
  </div>
  <div v-else>
    <GreenBlock>
      <v-container v-if="isNewOffer" class="new-offer-title">
        <h1 class="page-title">Willkommen zurück {{ userData.Anrede }} {{ userData.Nachname }}</h1>
        <div class="green-section__content">
          <div class="mobile--eprimo-guy">
            <img src="~@/assets/images/eprimo-face-2.svg" alt="eprimo-face">
          </div>
          <div class="green-section--left">
            <div class="page-subtitle">
              <span class="mob-width">Schön, dass Sie wieder hier sind! <br> Um auch weiterhin güstigen Ökostrom nutzen zu können, entscheiden Sie sich bitte für eines der folgenden Angebote für Ihr nächstes Strompaket.</span>
              <br><br>
              Damit profitieren Sie auch weiterhin von:
            </div>
            <ul class="list-checkmark">
              <li>sicheren Preisen für die gekaufte Menge - Sie kaufen Ihre Menge zum Festpreis</li>
              <li>flexibler Nutzung - ohne Vertragslaufzeit</li>
              <li>0 € monatlicher Grundgebühr</li>
              <li>weiteren Rabatten bei Einmalzahlung Ihres Paketes</li>
            </ul>
            <div class="">
              <a href="#packages" class="btn learn-more-btn">Zum neuen Angebot</a>
            </div>
          </div>
          <div class="green-section--right">
            <img src="~@/assets/images/eprimo-face.svg" alt="eprimo-face">
          </div>
        </div>
      </v-container>
      <v-container v-else>
        <h1 class="page-title">Guten Tag {{ userData.Anrede }} {{ userData.Nachname }}, wir haben ein ganz besonderes
          Angebot für Sie</h1>
        <div class="green-section__content">
          <div class="green-section--left">
            <div class="page-subtitle">Kaufen Sie Strompakete zu günstigen Festpreisen und schützen Sie sich so vor
              zukünftigen Preiserhöhungen – egal wie lange Sie benötigen, um diesen Strom zu verbrauchen.
            </div>
            <ul class="list-checkmark">
              <li>Keine Vertragslaufzeit</li>
              <li>Günstige feststehende Preise</li>
              <li>Strompakete sind unbegrenzt gültig</li>
              <li>100% grüner Strom</li>
              <li>Onlineprodukt, komplett digitale Abwicklung</li>
            </ul>
            <div class="text-center-mobile">
              <a href="#packages" class="btn learn-more-btn">Mehr erfahren</a>
            </div>
          </div>
          <div class="green-section--right">
            <img src="~@/assets/images/eprimo-face.svg" alt="eprimo-face">
          </div>
        </div>
      </v-container>
    </GreenBlock>
    <v-container>
      <div class="box-shadow" id="packages">
        <template v-if="isNewOffer">
          <h3 class="section__title">Wählen Sie einfach Ihr nächstes individuelles Paket</h3>
          <p class="new-offer-text">Basierend auf Ihren Verbrauchsdaten haben wir folgende alternative Angebote für Sie erstellt. Die vorhandene Restmenge Ihres bisherigen Strompaketes bleibt Ihnen
            selbstverständlich erhalten - Ihr neues Strompakte wird einfach dazu gebucht.</p>
        </template>
        <template v-else>
          <h3 class="section__title">Wählen Sie einfach Ihr individuelles Paket</h3>
          <p>Basierend auf Ihren Verbrauchsdaten haben wir <span v-if="isOnlyTwoPackages === true"> zwei</span> <span v-else>drei</span> Pakete für Sie berechnet.</p>
        </template>
        <div class="packages" v-bind:class="isNewOffer?'only-two-packages-wrap': ''">
          <template v-for="(item, index) in packages">
            <div class="package" v-bind:class="isNewOffer?'only-two-packages': ''" :id=" isNewOffer && !item.Name ? 'empty_offer': ''" :key="index">
              <div class="package-wrap" v-if="item.Name">
                <div class="package__box" :class="{'best-package': index === bestPackageIndex}">
                  <div class="package__discount" v-if="item.Discount > 0 && !isInitialFirstPackageEmpty">
                    <div>-{{ item.Discount }}%</div>
                  </div>
                  <h3 class="package__title">{{ item.Name + ' ' + item.NameSize }}</h3>
                  <ul class="list-checkmark package__list">
                    <li>Menge: {{ addDotToThousands(item.kwhQuantity) }} kWh</li>
                    <li>Reicht etwa: {{ item.EstimatedDuration }} Monate<sup>1</sup></li>

                    <li v-if="item.Discount > 0 && !isInitialFirstPackageEmpty">Rabatt Paketgröße: {{ item.Discount }}%</li>
                    <li v-else>Keine Mindestlaufzeit</li>
                  </ul>
                  <div class="package__price">
                    <div class="package__best-text" v-if="index === bestPackageIndex">eprimo empfiehlt</div>
                    <p class="price">{{ formatPrice(item.PriceMonthly) }} €<sup>2</sup></p>
                    Monatliche Rate bei Ratenkauf<span v-if="item.Discount > 0 && !isInitialFirstPackageEmpty"> (bereits rabattiert)</span>
                  </div>
                  <div>
                    Gesamtpreis für Paket:<br v-if="!isNewOffer">
                    {{ getSinglePrice(item.PriceMonthly, item.EstimatedDuration) }} €<sup>3</sup>
                  </div>
                  <button class="btn package__btn" @click="onPackageSelection(item)">Zum Angebot</button>
                </div>
              </div>
              <div class="package-wrap empty-package-block" v-else>
                <div v-if="isNewOffer">
                </div>
                <div class="package__box empty-package" v-else>
                  <div>
                    <h3>Ihr aktueller monatlicher Abschlag</h3>
                  </div>
                  <div>
                    <p>Preis pro Monat</p>
                    <h2>{{ formatPrice(userData.Current_PriceMonthly) }} €</h2>
                  </div>
                  <div>
                    <p>Jahresstrommenge</p>
                    <h2>{{ formatPrice(userData.Current_Consumption) }} kWh</h2>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="explanation-text">
          <p><sup>1</sup> Angaben sind geschätzt, basierend auf Ihren zurückliegenden Verbrauchsdaten gemäß Ihrer
            letzten Jahresrechnung.</p>
          <p><sup>2</sup> Sollten Sie sich für eine Ratenzahlung entscheiden, wird Ihnen eine Ratenzahlungsvereinbarung
            mit einem Ratenzahlungsplan in Ihr mein eprimo Postfach übersandt, aus dem Sie die jeweiligen Fälligkeiten
            entnehmen können. Die Gewährung der Ratenzahlung erfolgt zinsfrei über die von Ihnen gewählte Anzahl von
            Raten. Weitere Details entnehmen Sie bitte den hier einsehbaren
            <a v-if="isNewOffer" :href="`${publicPath}eprimo_agb_Strompakete_komplett_210622.pdf`" target="_blank">Strompakete-AGB</a>
            <a v-else :href="`${publicPath}agb_Ergaenzende_Bedingungen_Strompakete_20200901.pdf`" target="_blank">Strompakete-AGB</a>.
          </p>
          <p v-if="isNewOffer"><sup>3</sup> Basierend auf der Laufzeit und Ihrem aktuellen Grundpreis ergibt sich ein Arbeitspreis von Paket:
            <span v-for="(item, index) in packages" :key="index">
              <span v-if="item.Name"><span v-if="index > 0">, </span> {{ item.NameSize }}: {{ formatPrice(item.NewAP.replace(",", ".") * 100) }} ct/kWh</span>
            </span>. <br>
            Alle Preisangaben inkl. geltender gesetzlicher MwSt.</p>
          <p v-else><sup>3</sup> Rechnerischer Arbeitspreis bei Einmalzahlung je Paket:
            <span v-for="(item, index) in packages" :key="index">
              <span v-if="item.Name">
                <span>
                  {{ item.NameSize }}: {{ formatPrice(item.NewAP.replace(",", ".") * 100) }} ct/kWh<span
                    v-if="index <= 1">, </span>
                </span>
                </span>
            </span>. Die Arbeitspreisangaben beruhen auf den angegebenen Verbrauchsmonaten der Pakete. Alle
            Preisangaben verstehen sich inkl. der geltenden gesetzlichen MwSt. und sind kaufmännisch gerundet.</p>
        </div>
      </div>
    </v-container>
    <FAQ/>
  </div>
</template>

<script>
import FAQ from '@/components/FAQ.vue';
import GreenBlock from "@/components/GreenBlock";

export default {
  name: 'PackagesPage',
  components: {
    FAQ,
    GreenBlock
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      loading: false,
      packages: [],
      userData: [],
      isInitialFirstPackageEmpty: false,
      isOnlyTwoPackages: false,
      isNewOffer: false,
      isSmartMeterOrdered: false,
      bestPackageIndex: 1
    }
  },
  methods: {
    checkFirstPackageEmpty() {
      if (!this.packages[0].Name) {
        this.bestPackageIndex = 2;
        if (!this.isNewOffer) {
          this.isInitialFirstPackageEmpty = true;
        }
      }
    },
    checkOnlyTwoPackages() {
      if (!this.packages[0].Name || !this.packages[1].Name || !this.packages[2].Name) this.isOnlyTwoPackages = true;
    },
    onPackageSelection(p) {
      this.$router.push({
        name: 'formPage',
        params: {
          selectedPackage: p,
          lastPackage: this.packages[this.packages.length - 1],
          userData: this.userData,
          isInitialFirstPackageEmpty: this.isInitialFirstPackageEmpty,
          isNewOffer: this.isNewOffer,
          isSmartMeterOrdered: this.isSmartMeterOrdered
        }
      });
    },
    formatPrice(value) {
      let val = value.toString().replace(',', '.')
      val = parseFloat(val).toFixed(2).replace('.', ',');
      return this.addDotToThousands(val);
    },
    addDotToThousands(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getSinglePrice(priceMonthly, estimatedDuration) {
      var price = priceMonthly.toString().replace(',', '.')
      price = (price * estimatedDuration).toFixed(2).replace('.', ',')
      return this.addDotToThousands(price);
    }
  },
  mounted() {
    this.loading = true;
    this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'user/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        uuid: this.$route.params.uuid
      })
    })
        .then(res => res.json())
        .then(json => {
          if (json.success) {
            this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'data/output/' + this.$route.params.uuid, {
              method: 'GET'
            })
                .then(res => res.json())
                .then(json => {
                  if (!json.success) {
                    this.$router.push({name: 'error'});
                  } else {
                    this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'user/code/' + this.$route.params.uuid, {
                      method: 'GET'
                    })
                        .then(res => res.json())
                        .then(json => {
                          if (!json.success) {
                            this.$router.push({name: 'expiredCode'})
                          } else {
                            this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'data/' + this.$route.params.uuid, {
                              method: 'GET'
                            })
                                .then(res => res.json())
                                .then(json => {
                                  this.$nextTick(() => {
                                    this.checkFirstPackageEmpty();
                                    this.checkOnlyTwoPackages();
                                  });
                                  this.packages = json.packages;
                                  this.userData = json.user;
                                  this.isNewOffer = json.isNewOffer;
                                  this.isSmartMeterOrdered = json.isSmartMeterOrdered
                                  this.$cookies.set('uuid', this.$route.params.uuid)
                                });
                          }
                        })

                  }
                  this.loading = false;
                })
          } else {
            this.$cookies.remove('uuid');
            this.loading = false;
            if (json.message) {
              this.$router.push({
                name: 'expiredCode'
              });
            } else {
              this.$router.push({
                name: 'login',
                params: {
                  uuidProp: this.$route.params.uuid,
                  errorProp: true
                }
              });
            }
          }
        });
  }
}
</script>

<style lang="scss" scoped>
.green-section {
  margin-bottom: -63px;
}

.page-title {
  max-width: 700px;
  font-size: 32px;
  margin-bottom: 15px;
}

.packages {
  padding: 15px 0 20px;
}

#empty_offer {
  display: none;
}

.new-offer-title {
  padding-top: 10px;
  padding-bottom: 20px;

  .page-title {
    line-height: unset;
    margin-bottom: 20px;
  }

  .green-section--right {
    margin: 0;
    position: absolute;
    top: -120px;
    right: 0;
  }

  .mob-width br {
    display: none;
  }

  .mobile--eprimo-guy {
    display: none;
  }

  .page-subtitle {
    max-width: 62%;
  }

  .learn-more-btn {
    min-width: 268px;
  }
}

.new-offer-text {
  line-height: 25px;
}

.best-package {
  .package {
    &__title {
      background: $green;
      color: $white;
      margin: 0 -20px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }

  &.package__box {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

@media (min-width: 768px) {
  .packages {
    display: flex;
    margin: 0 -10px;

    &.only-two-packages-wrap {
      //justify-content: center;
    }
  }

  .package {
    padding: 0 10px;
  }
}

@media (max-width: 767px) {
  .package:not(:last-of-type) {
    margin-bottom: 40px;
  }

  .new-offer-title {
    .page-title {
      font-size: 20px;
      max-width: 70%;
    }

    .list-checkmark {
      font-size: 14px;
      line-height: 18px;

      li {
        padding-left: 40px;
        margin-bottom: 10px;

        &:before {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .page-subtitle {
      max-width: 100%;

      .mob-width {
        max-width: 85%;
        display: block;

        br {
          display: block;
        }
      }
    }

    .mobile--eprimo-guy {
      display: block;
      position: absolute;
      top: -100px;
      right: 0;

      img {
        width: 118px;
        height: 118px;
      }
    }
  }

  .new-offer-text {
    line-height: 19px;
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  h3.package__title {
    font-size: 23px;
  }
}

@media (max-width: 500px) {
  .text-center-mobile .learn-more-btn {
    display: none;
  }
}

@media (max-width: 350px) {
  .btn.package__btn {
    min-width: 90%;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {

  .new-offer-title {
    .green-section--right {
      top: -70px;

      img {
        max-width: 250px;
      }
    }
  }

  .package {
    .package__btn {
      min-width: 100%;
      padding: 6px 10px;
    }

    &__price {
      min-height: 90px;
    }

    &__discount {
      top: -30px;
    }
  }
}
</style>

<style lang="scss">
.package {
  width: 100%;

  &.only-two-packages {
    //width: auto;
    //
    //.package-wrap {
    //  width: 100%;
    //}

    .empty-package-block {
      display: none;
    }
  }

  &__box {
    border: 1px solid $grey-light-2;
    border-radius: 4px;
    padding: 0 20px 15px;
    text-align: center;
    min-height: 100%;
    position: relative;
  }

  &__discount {
    font-family: Synchro, sans-serif;
    font-size: 24px;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    position: absolute;
    right: -18px;
    top: -20px;
    color: $white;
    background-color: $yellow;

    div {
      background-color: $orange;
      width: 58px;
      height: 58px;
      margin: 3px;
      border-radius: 50%;
      transform: rotate(7deg);
      line-height: 50px;
    }
  }

  &__title {
    font-size: 25px;
    font-weight: 600;
    color: $green;
    line-height: 1.5;
    padding: 20px 0;
  }

  &__list {
    margin-top: 27px;
  }

  &__best-text {
    position: absolute;
    background-color: $green;
    min-width: 100%;
    width: calc(100% + 14px);
    font-size: 18px;
    font-weight: 700;
    left: -7px;
    top: -27px;
    padding: 3px 0;
    box-shadow: 0 3px 6px rgba(108, 108, 108, .96);

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -9px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 0 solid transparent;
      border-bottom: 9px solid $green-2;
      clear: both;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: -9px;
      width: 0;
      height: 0;
      border-left: 0 solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 9px solid $green-2;
      clear: both;
    }
  }

  &__price {
    position: relative;
    background-color: $grey;
    color: $white;
    font-weight: 600;
    margin: 50px -20px 8px;
    padding: 5px 30px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 75px;

    .price {
      font-size: 25px;
      margin-bottom: 3px;
    }

    span {
      font-size: 12px;
    }
  }

  .package__btn {
    margin-top: 15px;
    min-width: 100%;
  }
}

.empty-package-block {
  height: 100%;

  .empty-package {
    background-color: #73C72B;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;

    div {
      margin-bottom: 40px;
    }

    h3 {
      font-weight: bold;
      font-size: 21px;
    }

    h2 {
      font-weight: 600;
      font-size: 25px;
      line-height: 27px;
    }

    p {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

@media (max-width: 767px) {
  .package {
    &.only-two-packages {
      width: 100%;
    }
  }
}


</style>
